import * as PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby";

import { QuestionAnswer } from "../components/index";
import { tokenTypeType } from "../types";

class FaqMultiColorTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    const { question, route } = props.pageContext.faq;
    this.backBreadcrumPages = [
      {
        text: 'FAQ',
        to: '/faq/'
      },
      {
        text: question,
        to: route
      }
    ];
  }
  getTokens(tokens) {
    return (
      <ol>
      {
        tokens.map((token)=>{
          const { 
            name, id, power, toughness
          } = token.node;
          const pt = power ? `${power}/${toughness} ` : '';
          return (
            <li key={id}>
              <Link
                to={`/token-list/${id}/`}
                state={{
                  breadcrum: this.backBreadcrumPages
                }}
              >
                {pt}{name}
              </Link>
            </li>
          )
        })
      }
      </ol>
    )
  }
  render() {
    const {
      pageContext: {
        faq,
        tokens
      }
    } = this.props;
    const intro = `There are ${tokens.length} multicolored Magic tokens. A multicolor creature has more than two colors. The card frame background is gold which is why we also refer to these as gold tokens.`;
    const intro2 = `The ${tokens.length} multicolored tokens are:`;
    const htmlAnswer = `${intro}<br><br>${intro2}<br>${this.getTokens(tokens)}`;
    return (
      <QuestionAnswer {...faq} answer={htmlAnswer}>
        <>
          <p>{intro}</p>
          <p>{intro2}</p>
          {this.getTokens(tokens)}
        </>
      </QuestionAnswer>
    )
  }
}

FaqMultiColorTemplate.propTypes = {
  pageContext: PropTypes.shape({
    faq: PropTypes.shape({
      route: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      formattedDate: PropTypes.string.isRequired,
    }),
    tokens: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        ...tokenTypeType
      }).isRequired,
    })).isRequired,
  }).isRequired
}

export default FaqMultiColorTemplate